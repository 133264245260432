<template>
  <div>
    <Header2></Header2>
    <Slider></Slider>

    <!-- <Homeslider></Homeslider> -->
    <SiteContent></SiteContent>
    <Footer></Footer>
    <PopUp></PopUp>
  </div>
</template>

<script>
import Slider from "./Slider.vue";
import Header2 from "./Header2.vue";
import SiteContent from "./SiteContent.vue";
import Footer from "./Footer.vue";
import PopUp from "./PopUp.vue";
// import Homeslider from "./Homeslider.vue";

export default {
  name: "HomePage",
  components: {
    Slider,
    // Homeslider,
    Header2,
    SiteContent,
    Footer,
    PopUp,
  },
  data() {
    return {
      isPageReloaded: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    // Check if the page needs to be reloaded
    if (from && from.path !== "/" && to.path === "/") {
      next((vm) => {
        vm.$data.isPageReloaded = true;
        window.location.reload();
      });
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Check if the page needs to be reloaded
    if (from && from.path !== "/" && to.path === "/") {
      this.isPageReloaded = true;
      window.location.reload();
    } else {
      next();
    }
  },
};
</script>

<style scoped></style>
