<template>
  <div id="site-main" class="site-main">
    <div id="main-content" class="main-content">
      <div id="primary" class="content-area">
        <div id="content" class="site-content" role="main">
          <Slider></Slider>

          <section class="section section-padding m-b-70">
            <div class="section-container">
              <!-- Block Intro (Layout 2) -->
              <div class="block block-intro layout-2 text-center">
                <div class="block-widget-wrap">
                  <div class="intro-image animation-horizontal">
                    <img
                      width="120"
                      height="107"
                      src="media/banner/intro-2.png"
                      alt=""
                    />
                  </div>
                  <h2 class="intro-title">PRAMOD JEWELLERS</h2>
                  <div class="intro-text">
                    To provide the best designer jewelry at a reasonable price,
                    while revolutionizing the jewelry industry with transparency
                    and honesty.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section section-padding m-b-70">
            <div class="section-container">
              <!-- Block Banners (Layout 3) -->
              <div class="block block-banners layout-3 banners-effect">
                <div class="block-widget-wrap">
                  <div class="row">
                    <div class="section-column left">
                      <div class="section-column-inner">
                        <div class="block-widget-banner layout-3">
                          <div class="bg-banner">
                            <div class="banner-wrapper banners">
                              <router-link to="Gold">
                                <div
                                  class="banner-image"
                                  :style="{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                  }"
                                >
                                  <img
                                    width="400"
                                    height="600"
                                    src="media/product/goldnecklacehome.jpg"
                                    alt="Banner
                                  Image"
                                  />
                                </div>
                                <div class="banner-wrapper-infor goldcoltext">
                                  <div class="info">
                                    <div class="content">
                                      <h3
                                        class="title-banner"
                                        style="color: #ffffff"
                                      >
                                        Check Our Gold Collection
                                      </h3>
                                      <div
                                        style="color: #ffffff"
                                        class="banner-image-description"
                                      >
                                        Brighten up your look with vibrant
                                        gemstone jewelry.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="section-column right">
                      <div class="section-column-inner">
                        <div class="block-widget-banner layout-3">
                          <div class="bg-banner">
                            <div class="banner-wrapper banners">
                              <router-link to="platinum">
                                <div
                                  class="banner-image"
                                  :style="{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                  }"
                                >
                                  <img
                                    width="400"
                                    height="600"
                                    src="media/blog/diamondcollection4.jpg"
                                    alt="Banner
                                  Image"
                                  />
                                </div>
                                <div class="banner-wrapper-infor">
                                  <div class="info">
                                    <div class="content">
                                      <h3 class="title-banner">
                                        Check Our Diamond Collection
                                      </h3>
                                      <div class="banner-image-description">
                                        Brighten up your look with vibrant
                                        gemstone jewelry.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="block-widget-banner layout-3">
                          <div class="bg-banner">
                            <div class="banner-wrapper banners">
                              <router-link to="Silver">
                                <div
                                  class="banner-image"
                                  :style="{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                  }"
                                >
                                  <img
                                    width="400"
                                    height="600"
                                    src="media/newproducts/silvhome.avif"
                                    alt="Banner Image"
                                  />
                                </div>
                                <div class="banner-wrapper-infor">
                                  <div class="info">
                                    <div class="content">
                                      <h3 class="title-banner">
                                        Check Our Silver Collection
                                      </h3>
                                      <div class="banner-image-description">
                                        Brighten up your look with vibrant
                                        gemstone jewelry.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            class="section section-padding background-img bg-img-3 p-t-60 p-b-70 m-b-70"
          >
            <div class="section-container">
              <!-- Block Products (Layout 2) -->
              <div class="block block-products layout-2 slider">
                <div class="block-widget-wrap">
                  <div class="block-title txt_cent">
                    <h2 class="marbot">Trending Products</h2>
                  </div>
                  <div class="block-content">
                    <div class="content-product-list slick-wrap">
                      <div
                        class="slick-sliders products-list grid"
                        data-slidestoscroll="true"
                        data-dots="false"
                        data-nav="1"
                        data-columns4="1"
                        data-columns3="2"
                        data-columns2="2"
                        data-columns1="3"
                        data-columns1440="4"
                        data-columns="4"
                      >
                        <div class="item-product slick-slide">
                          <div class="items">
                            <div
                              class="products-entry clearfix product-wapper"
                              style="padding-bottom: 0"
                            >
                              <div class="products-thumb">
                                <div class="product-thumb-hover">
                                  <router-link to="/earring">
                                    <img
                                      style="width: 100%; height: 300px"
                                      src="media/trendingproducts/0G1A5478.jpg"
                                      class="post-image"
                                      alt=""
                                    />
                                    <img
                                      style="width: 100%; height: 300px"
                                      src="media/trendingproducts/0G1A5479.jpg"
                                      class="hover-image back"
                                      alt=""
                                    />
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="item-product slick-slide">
                          <div class="items">
                            <div
                              class="products-entry clearfix product-wapper"
                              style="padding-bottom: 0"
                            >
                              <div class="products-thumb">
                                <div class="product-thumb-hover">
                                  <router-link to="/neckless">
                                    <a href="#">
                                      <img
                                        style="width: 100%; height: 300px"
                                        src="media/trendingproducts/0G1A5426.jpg"
                                        class="post-image"
                                        alt=""
                                      />
                                      <img
                                        style="width: 100%; height: 300px"
                                        src="media/trendingproducts/0G1A5425.jpg"
                                        class="hover-image back"
                                        alt=""
                                      />
                                    </a>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="item-product slick-slide">
                          <div class="items">
                            <div
                              class="products-entry clearfix product-wapper"
                              style="padding-bottom: 0"
                            >
                              <div class="products-thumb">
                                <div class="product-thumb-hover">
                                  <router-link to="/ring">
                                    <a href="#">
                                      <img
                                        style="width: 100%; height: 300px"
                                        src="media/trendingproducts/0G1A3153.jpg"
                                        class="post-image"
                                        alt=""
                                      />
                                      <img
                                        style="width: 100%; height: 300px"
                                        src="media/trendingproducts/0G1A3155.jpg"
                                        class="hover-image back"
                                        alt=""
                                      />
                                    </a>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="item-product slick-slide">
                          <div class="items">
                            <div
                              class="products-entry clearfix product-wapper"
                              style="padding-bottom: 0"
                            >
                              <div class="products-thumb">
                                <div class="product-thumb-hover">
                                  <router-link to="/breclate">
                                    <a href="#">
                                      <img
                                        style="width: 100%; height: 300px"
                                        src="media/trendingproducts/0G1A3114.jpg"
                                        class="post-image"
                                        alt=""
                                      />
                                      <img
                                        style="width: 100%; height: 300px"
                                        src="media/trendingproducts/0G1A3115.jpg"
                                        class="hover-image back"
                                        alt=""
                                      />
                                    </a>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="item-product slick-slide">
                          <div class="items">
                            <div
                              class="products-entry clearfix product-wapper"
                              style="padding-bottom: 0"
                            >
                              <div class="products-thumb">
                                <div class="product-thumb-hover">
                                  <router-link to="/neckless">
                                    <a href="#">
                                      <img
                                        style="width: 100%; height: 300px"
                                        src="media/trendingproducts/0G1A3010.jpg"
                                        class="post-image"
                                        alt=""
                                      />
                                      <img
                                        style="width: 100%; height: 300px"
                                        src="media/trendingproducts/0G1A3011.jpg"
                                        class="hover-image back"
                                        alt=""
                                      />
                                    </a>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section section-padding m-b-70">
            <div class="section-container">
              <!-- Block Intro (Layout 3) -->
              <div class="block block-intro layout-3">
                <div class="block-widget-wrap">
                  <div class="row">
                    <div class="section-column left">
                      <div class="intro-wrap">
                        <h2 class="intro-title">
                          Pramod Jewellers &<br />
                          Gift Center
                        </h2>
                        <div class="intro-item">
                          "Pramod Jewellers & Gift Center Nagpur is known to
                          satisfactorily cater the demands of its customer base.
                          Customer service is at the core of Pramod Jewellers &
                          Gift Center in Pardhi Nagar, Hingna Road, Nagpur and
                          it is this belief that has led the business to build
                          long-term relationships. Ensuring a positive customer
                          experience, making available goods and/or services
                          that are of top-notch quality is given importance. It
                          is one of the players in Pendants, Necklace, Gold
                          Ring, Mangalsutra, and Swivel Ring to name a few. Also
                          known for Jewellery Showrooms, Gold Jewellery
                          Showrooms, Gemstone Dealers, Diamond Jewellery
                          Showrooms, Bangle Dealers, Imitation Jewellery
                          Showrooms, Silver Jewellery Showrooms, Antique
                          Jewellery Showrooms and much more."
                        </div>
                        <div class="intro-item">
                          Visit our online catalogue and shop for the finest
                          earrings, rings, bracelets, watches, silver, and the
                          most luxurious gemstones.
                        </div>
                        <div class="intro-btn">
                          <router-link
                            to="/about"
                            class="button button-outline animation-horizontal"
                            >Read more</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="section-column right animation-horizontal hover-opacity"
                    >
                      <!-- <img
                        width="690"
                        height="498"
                        src="media/banner/intro-2.jpg"
                        alt="intro"
                      /> -->
                      <video width="640" height="498" controls loop>
                        <source src="media/video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section section-padding top-border p-t-70 m-b-70">
            <div class="section-container">
              <!-- Block Product Categories -->
              <div class="block block-product-cats slider round-border">
                <div class="block-widget-wrap">
                  <div class="block-title txt_cent">
                    <h2 class="marbot">Top Categories</h2>
                  </div>
                  <div class="block-content">
                    <div class="product-cats-list slick-wrap">
                      <div
                        class="slick-sliders content-category"
                        data-dots="0"
                        data-slidestoscroll="true"
                        data-nav="1"
                        data-columns4="2"
                        data-columns3="3"
                        data-columns2="3"
                        data-columns1="5"
                        data-columns1440="5"
                        data-columns="5"
                      >
                        <div class="item item-product-cat slick-slide">
                          <div class="item-product-cat-content">
                            <router-link to="/breclate">
                              <div class="item-image animation-horizontal">
                                <img
                                  style="width: 100%; height: 190px"
                                  src="media/topcategories/0G1A3235.jpg"
                                  alt="Bracelets"
                                />
                              </div>
                            </router-link>
                            <div class="product-cat-content-info">
                              <h2 class="item-title">
                                <router-link to="/breclate"
                                  >Bracelets</router-link
                                >
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div class="item item-product-cat slick-slide">
                          <div class="item-product-cat-content">
                            <router-link to="/Jwelrybox">
                              <div class="item-image animation-horizontal">
                                <img
                                  style="width: 100%; height: 190px"
                                  src="media/topcategories/0G1A5239.jpg"
                                  alt="Charms"
                                />
                              </div>
                            </router-link>
                            <div class="product-cat-content-info">
                              <h2 class="item-title">
                                <router-link to="/Jwelrybox"
                                  >Jewellerybox</router-link
                                >
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div class="item item-product-cat slick-slide">
                          <div class="item-product-cat-content">
                            <router-link to="/earring">
                              <div class="item-image animation-horizontal">
                                <img
                                  style="width: 100%; height: 190px"
                                  src="media/topcategories/0G1A5471.jpg"
                                  alt="Earrings"
                                />
                              </div>
                            </router-link>
                            <div class="product-cat-content-info">
                              <h2 class="item-title">
                                <router-link to="/earring"
                                  >Earrings</router-link
                                >
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div class="item item-product-cat slick-slide">
                          <div class="item-product-cat-content">
                            <router-link to="/neckless">
                              <div class="item-image animation-horizontal">
                                <img
                                  style="width: 100%; height: 190px"
                                  src="media/topcategories/0G1A5464.jpg"
                                  alt="Necklaces"
                                />
                              </div>
                            </router-link>
                            <div class="product-cat-content-info">
                              <h2 class="item-title">
                                <router-link to="/neckless"
                                  >Necklaces
                                </router-link>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div class="item item-product-cat slick-slide">
                          <div class="item-product-cat-content">
                            <router-link to="/ring">
                              <div class="item-image animation-horizontal">
                                <img
                                  style="width: 100%; height: 190px"
                                  src="media/topcategories/0G1A3143.jpg"
                                  alt="Rings"
                                />
                              </div>
                            </router-link>
                            <div class="product-cat-content-info">
                              <h2 class="item-title">
                                <router-link to="/ring">Rings</router-link>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div class="item item-product-cat slick-slide">
                          <div class="item-product-cat-content">
                            <router-link to="/breclate">
                              <div class="item-image animation-horizontal">
                                <img
                                  style="width: 100%; height: 190px"
                                  src="media/topcategories/0G1A3115.jpg"
                                  alt="Bracelets"
                                />
                              </div>
                            </router-link>
                            <div class="product-cat-content-info">
                              <h2 class="item-title">
                                <router-link to="/breclate"
                                  >Bracelets</router-link
                                >
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section section-padding top-border p-t-70 m-b-70">
            <div class="section-container">
              <!-- Block Testimonial -->
              <div class="block block-testimonial layout-2">
                <div class="block-widget-wrap">
                  <div class="block-title txt_cent">
                    <h2 class="marbot">Happy Clients Says</h2>
                  </div>
                  <div class="block-content">
                    <div class="testimonial-wrap slick-wrap">
                      <div
                        class="slick-sliders"
                        data-slidestoscroll="true"
                        data-nav="1"
                        data-dots="0"
                        data-columns4="1"
                        data-columns3="1"
                        data-columns2="1"
                        data-columns1="2"
                        data-columns="3"
                      >
                        <div class="testimonial-content">
                          <div class="item">
                            <div class="testimonial-item">
                              <div class="testimonial-icon">
                                <div class="rating">
                                  <div class="star star-5"></div>
                                </div>
                              </div>
                              <h2 class="testimonial-title"></h2>
                              <div class="testimonial-excerpt">
                                I am extremely satisfied with the products on
                                this website. The quality is top-notch, and the
                                prices are reasonable. Definitely recommending
                                it to my friends and family!"
                              </div>
                            </div>
                            <div class="testimonial-image image-position-top">
                              <div class="thumbnail">
                                <img
                                  width="110"
                                  height="110"
                                  src="https://i0.wp.com/ctmirror-images.s3.amazonaws.com/wp-content/uploads/2021/01/dummy-man-570x570-1.png?fit=570%2C570&ssl=1"
                                  alt="img"
                                />
                              </div>
                              <div class="testimonial-info">
                                <h2 class="testimonial-customer-name">
                                  Aarav Sharma
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="testimonial-content">
                          <div class="item">
                            <div class="testimonial-item">
                              <div class="testimonial-icon">
                                <div class="rating">
                                  <div class="star star-4"></div>
                                </div>
                              </div>
                              <h2 class="testimonial-title"></h2>
                              <div class="testimonial-excerpt">
                                Great online shopping experience! The website is
                                user-friendly, and the delivery was quick. I'll
                                be a repeat customer for sure
                              </div>
                            </div>
                            <div class="testimonial-image image-position-top">
                              <div class="thumbnail">
                                <img
                                  width="110"
                                  height="110"
                                  src="https://cdn.pixabay.com/photo/2015/10/18/20/15/woman-995164_1280.png"
                                  alt="img"
                                />
                              </div>
                              <div class="testimonial-info">
                                <h2 class="testimonial-customer-name">
                                  Nandini Patel
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="testimonial-content">
                          <div class="item">
                            <div class="testimonial-item">
                              <div class="testimonial-icon">
                                <div class="rating">
                                  <div class="star star-5"></div>
                                </div>
                              </div>

                              <div class="testimonial-excerpt">
                                The variety of products available here is
                                amazing. I found exactly what I was looking for,
                                and the customer service was excellent. Five
                                stars!
                              </div>
                            </div>
                            <div class="testimonial-image image-position-top">
                              <div class="thumbnail">
                                <img
                                  width="110"
                                  height="110"
                                  src="https://i0.wp.com/ctmirror-images.s3.amazonaws.com/wp-content/uploads/2021/01/dummy-man-570x570-1.png?fit=570%2C570&ssl=1"
                                  alt="img"
                                />
                              </div>
                              <div class="testimonial-info">
                                <h2 class="testimonial-customer-name">
                                  Vikram Singhania
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="testimonial-content">
                          <div class="item">
                            <div class="testimonial-item">
                              <div class="testimonial-icon">
                                <div class="rating">
                                  <div class="star star-5"></div>
                                </div>
                              </div>
                              <h2 class="testimonial-title"></h2>
                              <div class="testimonial-excerpt">
                                "Impressed with the timely delivery and the
                                quality of the items. The prices are
                                competitive, and I appreciate the great customer
                                service.
                              </div>
                            </div>
                            <div class="testimonial-image image-position-top">
                              <div class="thumbnail">
                                <img
                                  width="110"
                                  height="110"
                                  src="https://i0.wp.com/ctmirror-images.s3.amazonaws.com/wp-content/uploads/2021/01/dummy-man-570x570-1.png?fit=570%2C570&ssl=1"
                                  alt="img"
                                />
                              </div>
                              <div class="testimonial-info">
                                <h2 class="testimonial-customer-name">
                                  Rajat Verma
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section section-padding top-border p-t-70 m-b-70">
            <div class="section-container">
              <!-- Block Newsletter (Layout 2) -->
              <div class="block block-newsletter layout-2 one-col">
                <div class="block-widget-wrap">
                  <div class="newsletter-title-wrap">
                    <h2 class="newsletter-title">
                      Latest From Pramod Jewellers!
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section m-b-0">
            <!-- Block Instagram -->
            <div class="block block-instagram slider no-space">
              <div class="slick-wrap">
                <div
                  class="slick-sliders fullwidth"
                  data-dots="false"
                  data-item_row="1"
                  data-nav="false"
                  data-columns4="1"
                  data-columns3="2"
                  data-columns2="3"
                  data-columns1="4"
                  data-columns="5"
                >
                  <div class="item slick-slide">
                    <a class="instagram" href="#">
                      <img
                        width="450"
                        height="450"
                        src="media/banner/instagram-1.jpg"
                        alt="Image Slider"
                      />
                    </a>
                  </div>
                  <div class="item slick-slide">
                    <a class="instagram" href="#">
                      <img
                        width="450"
                        height="450"
                        src="media/banner/instagram-2.jpg"
                        alt="Image Slider"
                      />
                    </a>
                  </div>
                  <div class="item slick-slide">
                    <a class="instagram" href="#">
                      <img
                        width="450"
                        height="450"
                        src="media/banner/instagram-3.jpg"
                        alt="Image Slider"
                      />
                    </a>
                  </div>
                  <div class="item slick-slide">
                    <a class="instagram" href="#">
                      <img
                        width="450"
                        height="450"
                        src="media/banner/instagram-4.jpg"
                        alt="Image Slider"
                      />
                    </a>
                  </div>
                  <div class="item slick-slide">
                    <a class="instagram" href="#">
                      <img
                        width="450"
                        height="450"
                        src="media/banner/instagram-5.jpg"
                        alt="Image Slider"
                      />
                    </a>
                  </div>
                  <div class="item slick-slide">
                    <a class="instagram" href="#">
                      <img
                        width="450"
                        height="450"
                        src="media/banner/instagram-6.jpg"
                        alt="Image Slider"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <!-- #content -->
      </div>
      <!-- #primary -->
    </div>
    <!-- #main-content -->
  </div>
</template>

<script>
export default {
  name: "SiteContent",
};
</script>
