<template>
  <Header2></Header2>
  <body class="shop">
    <div id="page" class="hfeed page-wrapper">
      <div id="site-main" class="site-main">
        <div id="main-content" class="main-content">
          <div id="primary" class="content-area">
            <div
              id="title"
              class="page-title"
              style="
                background-image: url(' media/blog/3.jpg');
                background-size: cover;
                background-position: center center;
                width: 100%;
                height: 300px;
                text-align: left;
              "
            >
              <div class="section-container">
                <div class="content-title-heading">
                  <h1
                    class="text-title-heading"
                    style="color: #ffffff; margin-left: 100px"
                  >
                    Diamond
                  </h1>
                </div>
              </div>
            </div>

            <div id="content" class="site-content" role="main">
              <div class="section-padding">
                <div class="section-container p-l-r">
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                      <div class="tab-content">
                        <div
                          class="tab-pane fade show active"
                          id="layout-grid"
                          role="tabpanel"
                        >
                          <div class="products-list grid">
                            <div class="row">
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/banner/instagram-2.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/banner/instagram-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/banner/instagram-1.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/banner/instagram-1.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/banner/instagram-4.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/banner/instagram-4.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/banner/instagram-5.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/banner/instagram-5.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/banner/instagram-6.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/banner/instagram-6.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/il_fullxfull.2846301318_o6nm.avif"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/il_fullxfull.2846301318_o6nm.avif"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/6-e1648647519362.png"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/6-e1648647519362.png"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/magnificent-30-00-carat-diamond-necklace-gia_2_90-91-438.webp"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/magnificent-30-00-carat-diamond-necklace-gia_2_90-91-438.webp"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/estate-graduated-diamond-necklace_1_90-3-11551.webp"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/estate-graduated-diamond-necklace_1_90-3-11551.webp"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/silver/0G1A3208.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/silver/0G1A3208.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/Thakorlal-Hiralal-01-scaled.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/Thakorlal-Hiralal-01-scaled.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/il_1080xN.1969917707_o7h1.avif"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/platinum/il_1080xN.1969917707_o7h1.avif"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- #content -->
          </div>
          <!-- #primary -->
        </div>
        <!-- #main-content -->
      </div>

      <Footer></Footer>
    </div>

    <!-- Back Top button -->
    <div class="back-top button-show" @click="scrollToTop">
      <i class="arrow_carrot-up"></i>
    </div>

    <!-- Search -->

    <!-- Wishlist -->

    <!-- Compare -->

    <!-- Quickview -->

    <!-- Page Loader -->
  </body>
</template>

<script>
import Header2 from "../Header2.vue";
import Footer from "../Footer.vue";
export default {
  name: "PlatinumPage",
  components: {
    Header2,
    Footer,
  },
  methods: {
    scrollToTop() {
      // Smooth scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
