<template>
  <footer
    id="site-footer"
    class="site-footer background"
    style="background: #000000"
  >
    <div class="footer">
      <div class="section-padding">
        <div class="section-container">
          <div class="block-widget-wrap">
            <div class="row">
              <div class="col-lg-5 col-md-6">
                <div class="block block-menu m-b-20">
                  <h2 class="block-title" style="color: #ffffff">Contact Us</h2>
                  <div class="block-content">
                    <ul>
                      <li>
                        <span class="contact-us" style="color: #ffffff"
                          >Add:</span
                        >
                        MIDC, Hingna Rd, Pardhi Nagar, Maharashtra 440016<br />
                        Hingna, Nagpur
                      </li>
                      <li>
                        <span class="contact-us" style="color: #ffffff"
                          >Tel:</span
                        >
                        098503 58099
                      </li>
                      <li>
                        <span class="contact-us" style="color: #ffffff"
                          >Email:</span
                        >
                        nishant6699dhomne@gmail.com
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="block block-social">
                  <ul class="social-link">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=100082407999423&mibextid=ZbWKwL"
                        ><i class="fa fa-facebook" style="color: #ffffff"></i
                      ></a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/pramodjewellershingnanagpur?igsh=MWx6eTljYWpramtyNg=="
                        ><i class="fa fa-instagram" style="color: #ffffff"></i
                      ></a>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/+9850358099?text=Welcome%20to%20Pramod%20%20Jewellers%20"
                        target="_blank"
                        ><i class="fa fa-whatsapp" style="color: #ffffff"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="block block-menu">
                  <h2 class="block-title" style="color: #ffffff">About Us</h2>
                  <div class="block-content">
                    <ul>
                      <li>
                        <router-link to="/about">About Us</router-link>
                      </li>
                      <li>
                        <router-link to="/contact">Contact us</router-link>
                      </li>
                      <li>
                        <router-link to="/Gold">Our Products</router-link>
                      </li>
                      <li>
                        <router-link to="/privicy-policy">Privacy Policy</router-link>
                      </li>  <li>
                        <router-link to="/tc">Terms & Conditions</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="block block-menu">
                  <h2 class="block-title" style="color: #ffffff">Catalog</h2>
                  <div class="block-content">
                    <ul>
                      <li>
                        <router-link to="/earring">Earrings</router-link>
                      </li>
                      <li>
                        <router-link to="/neckless">Necklaces</router-link>
                      </li>
                      <li>
                        <router-link to="/breclate">Bracelets</router-link>
                      </li>
                      <li>
                        <router-link to="/ring">Rings</router-link>
                      </li>
                      <li>
                        <router-link to="/Jwelrybox">Jewelry Box</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="section-padding">
        <div class="section-container">
          <div class="block-widget-wrap">
            <div class="row">
              <div class="col-md-6">
                <div class="footer-left">
                  <p class="copyright" style="color: #ffffff">
                    Copyright © 2024 Pramod Jewellers . All rights reserved
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="footer-right">
                  <div class="block block-image" style="color: #ffffff">
                    <!-- <p class="Developdby">Developed by Izequal2tech</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterPage",
};
</script>
