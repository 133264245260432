<template>
  <section class="section m-b-70">
    <!-- Block Sliders -->
    <div class="block block-sliders auto-height color-white nav-center">
      <div
        class="slick-sliders slid"
        data-dots="true"
        data-nav="true"
        data-columns4="1"
        data-columns3="1"
        data-columns2="1"
        data-columns1="1"
        data-columns1440="1"
        data-columns="1"
        data-autoplay="true"
        data-autoplay-speed="1000"
      >
        <div class="item slick-slide">
          <div class="item-content">
            <div class="content-image">
              <img width="1920" src="media/slider/1-3.jpg" alt="Image Slider" />
              <!-- src="media/slider/1-3.jpg" -->
            </div>
            <div class="item-info horizontal-start vertical-middle">
              <div class="content">
                <h2 class="title-slider">
                  Welcome to<br />
                  Pramod Jewellers
                </h2>
                <router-link
                  class="button-slider button button-white button-outline thick-border"
                  to="/contact"
                  >Visit Now</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="item slick-slide">
          <div class="item-content">
            <div class="content-image">
              <img
                width="1920"
                height="1080"
                src="media/about/banimg5.jpg"
                alt="Image Slider"
              />
            </div>
            <div class="item-info horizontal-start vertical-middle">
              <div class="content">
                <h2 class="title-slider">Discover a <br />world of jewelry</h2>
                <a
                  class="button-slider button button-white button-outline thick-border"
                  href="shop-grid-left.html"
                  >Explore Bestseller</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SliderPage",
};
</script>
