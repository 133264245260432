<template>
  <header
    :style="{ color: '#dcdcdc' }"
    id="site-header"
    class="site-header header-v1"
  >
    <div class="header-mobile">
      <div class="section-padding">
        <div class="section-container">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-3 header-left">
              <div class="navbar-header">
                <button
                  type="button"
                  id="show-megamenu"
                  class="navbar-toggle"
                  @click="toggleMenu"
                  :class="{ active: isMenuOpen }"
                ></button>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 header-center"
            >
              <div class="site-logo">
                <router-link to="/">
                  <img
                    :style="{ height: '50px', maxBlockSize: 'fit-content' }"
                    src="media/newpjew.png"
                    alt="Mojuri – Jewelry Store HTML Template"
                  />
                </router-link>
              </div>
            </div>
            <!-- <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-3 header-right">
              <div class="mojuri-topcart dropdown">
                <div class="dropdown mini-cart top-cart">
                  <div class="remove-cart-shadow"></div>
                  <a
                    class="dropdown-toggle cart-icon"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div class="icons-cart">
                      <i class="icon-large-paper-bag"></i
                      ><span class="cart-count">2</span>
                    </div>
                  </a>
                  <div class="dropdown-menu cart-popup">
                    <div class="cart-empty-wrap">
                      <ul class="cart-list">
                        <li class="empty">
                          <span>No products in the cart.</span>
                          <a class="go-shop" href="shop-grid-left.html"
                            >GO TO SHOP<i
                              aria-hidden="true"
                              class="arrow_right"
                            ></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                    <div class="cart-list-wrap">
                      <ul class="cart-list">
                        <li class="mini-cart-item">
                          <a href="#" class="remove" title="Remove this item"
                            ><i class="icon_close"></i
                          ></a>
                          <a href="shop-details.html" class="product-image"
                            ><img
                              width="600"
                              height="600"
                              src="media/product/3.jpg"
                              alt=""
                          /></a>
                          <a href="shop-details.html" class="product-name"
                            >Twin Hoops</a
                          >
                          <div class="quantity">Qty: 1</div>
                          <div class="price">$150.00</div>
                        </li>
                        <li class="mini-cart-item">
                          <a href="#" class="remove" title="Remove this item"
                            ><i class="icon_close"></i
                          ></a>
                          <a href="shop-details.html" class="product-image"
                            ><img
                              width="600"
                              height="600"
                              src="media/product/1.jpg"
                              alt=""
                          /></a>
                          <a href="shop-details.html" class="product-name"
                            >Medium Flat Hoops</a
                          >
                          <div class="quantity">Qty: 1</div>
                          <div class="price">$100.00</div>
                        </li>
                      </ul>
                      <div class="total-cart">
                        <div class="title-total">Total:</div>
                        <div class="total-price"><span>$250.00</span></div>
                      </div>
                      <div class="free-ship">
                        <div class="title-ship">
                          Buy <strong>$400</strong> more to enjoy
                          <strong>FREE Shipping</strong>
                        </div>
                        <div class="total-percent">
                          <div class="percent" style="width: 20%"></div>
                        </div>
                      </div>
                      <div class="buttons">
                        <a
                          href="shop-cart.html"
                          class="button btn view-cart btn-primary"
                          >View cart</a
                        >
                        <a
                          href="shop-checkout.html"
                          class="button btn checkout btn-default"
                          >Check out</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div v-if="isMenuOpen" class="mobile-menu">
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/Platinum">Diamond</router-link></li>
          <li><router-link to="/Gold">Gold</router-link></li>
          <li><router-link to="/Silver">Silver</router-link></li>
          <li><router-link to="/earring">Earrings</router-link></li>
          <li><router-link to="/ring">Rings</router-link></li>
          <li><router-link to="/neckless">Necklaces</router-link></li>
          <li><router-link to="/Jwelrybox">Jewellery Box</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
          <li><router-link to="/gallery">Gallery</router-link></li>
        </ul>
      </div>

      <!-- <div class="header-mobile-fixed">
        <div class="shop-page">
          <a href="shop-grid-left.html"><i class="wpb-icon-shop"></i></a>
        </div>

        <div class="my-account">
          <div class="login-header">
            <a href="page-my-account.html"><i class="wpb-icon-user"></i></a>
          </div>
        </div>

        <div class="search-box">
          <div class="search-toggle">
            <i class="wpb-icon-magnifying-glass"></i>
          </div>
        </div>

        <div class="wishlist-box">
          <a href="shop-wishlist.html"><i class="wpb-icon-heart"></i></a>
        </div>
      </div> -->
    </div>

    <div class="header-desktop" :style="{ padding: '10px' }">
      <div class="header-wrapper">
        <div class="section-padding">
          <div class="section-container large p-l-r">
            <div class="row">
              <div
                class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 header-left"
                style="padding-left: 25px"
              >
                <div class="site-logo">
                  <router-link to="/">
                    <img
                      :style="{
                        height: '65px',
                        maxBlockSize: 'fit-content',
                      }"
                      src="media/newpjew.png"
                      alt="Pramod jewelry logo"
                  /></router-link>
                </div>
              </div>

              <div
                class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-center header-center"
              >
                <div class="site-navigation">
                  <nav id="main-navigation">
                    <ul id="menu-main-menu" class="menu">
                      <li class="level-0">
                        <router-link to="/"
                          ><span class="menu-item-text">Home </span>
                        </router-link>
                      </li>
                      <li class="level-0">
                        <!-- <router-link to='/about'><span class="menu-item-text">About</span>
                                                <router-link /> -->
                        <router-link to="/about"
                          ><span class="menu-item-text"
                            >About
                          </span></router-link
                        >
                      </li>
                      <li
                        class="level-0 menu-item menu-item-has-children mega-menu mega-menu-fullwidth align-center"
                      >
                        <router-link to="#"
                          ><span class="menu-item-text"
                            >Products</span
                          ></router-link
                        >
                        <div class="sub-menu">
                          <div class="row">
                            <div class="col-md-5">
                              <!-- <div class="menu-section">
                                                            <h2 class="sub-menu-title">Blog Category</h2>
                                                            <ul class="menu-list">
                                                                <li>
                                                                    <a href="blog-grid-left.html"><span class="menu-item-text">Blog Grid - Left Sidebar</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="blog-grid-right.html"><span class="menu-item-text">Blog Grid - Right Sidebar</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="blog-list-left.html"><span class="menu-item-text">Blog List - Left Sidebar</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="blog-list-right.html"><span class="menu-item-text">Blog List - Right Sidebar</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="blog-grid-fullwidth.html"><span class="menu-item-text">Blog Grid - No Sidebar</span></a>
                                                                </li>
                                                            </ul>
                                                        </div> -->

                              <div class="menu-section">
                                <h2 class="sub-menu-title">Product Details</h2>
                                <ul class="menu-list">
                                  <li>
                                    <router-link to="/earring"
                                      ><span class="menu-item-text"
                                        >Earrings</span
                                      ></router-link
                                    >
                                  </li>
                                  <li>
                                    <router-link to="/neckless"
                                      ><span class="menu-item-text"
                                        >Necklaces</span
                                      ></router-link
                                    >
                                  </li>
                                  <li>
                                    <router-link to="/breclate"
                                      ><span class="menu-item-text"
                                        >Bracelets
                                      </span></router-link
                                    >
                                  </li>
                                  <li>
                                    <router-link to="/ring"
                                      ><span class="menu-item-text"
                                        >Rings
                                      </span></router-link
                                    >
                                  </li>
                                  <li>
                                    <router-link to="/Jwelrybox"
                                      ><span class="menu-item-text"
                                        >Jewelry Box
                                      </span></router-link
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-7">
                              <div class="menu-section">
                                <h2 class="sub-menu-title">Our Products</h2>
                                <div
                                  class="block block-posts recent-posts p-t-5"
                                >
                                  <ul class="posts-list">
                                    <li
                                      class="post-item"
                                      style="align-items: center"
                                    >
                                      <router-link
                                        to="/platinum"
                                        class="post-image"
                                      >
                                        <img src="media/blog/3.jpg" />
                                      </router-link>
                                      <div class="post-content">
                                        <h2 class="post-title">
                                          <router-link to="/platinum">
                                            DIAMOND
                                          </router-link>
                                        </h2>
                                      </div>
                                    </li>
                                    <li
                                      class="post-item"
                                      style="align-items: center"
                                    >
                                      <router-link
                                        to="/Gold"
                                        class="post-image"
                                      >
                                        <img src="media/gold/0G1A5232.jpg" />
                                      </router-link>
                                      <div class="post-content">
                                        <h2 class="post-title">
                                          <router-link to="/Gold">
                                            GOLD
                                          </router-link>
                                        </h2>
                                      </div>
                                    </li>
                                    <li
                                      class="post-item"
                                      style="align-items: center"
                                    >
                                      <router-link
                                        to="/Silver"
                                        class="post-image"
                                      >
                                        <img src="media/blog/2.jpg" />
                                      </router-link>
                                      <div class="post-content">
                                        <h2 class="post-title">
                                          <router-link to="/Silver">
                                            SILVER
                                          </router-link>
                                        </h2>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li class="level-0 menu-item">
                        <router-link to="/contact"
                          ><span class="menu-item-text"
                            >Contact</span
                          ></router-link
                        >
                      </li>
                      <li class="level-0 menu-item">
                        <router-link to="/gallery"
                          ><span class="menu-item-text"
                            >Gallery</span
                          ></router-link
                        >
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderPage2",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      console.log("first");
      this.isMenuOpen = !this.isMenuOpen;
    },
    // ... (your other methods) ...
  },
};
</script>
<style scoped>
.mobile-menu {
  background-color: #fff; /* Add your preferred styles for the menu */
  position: absolute;
  top: 60px; /* Adjust based on your header height */
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu li {
  margin-bottom: 10px;
}

.mobile-menu a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 16px;
}
</style>
