<template>
  <body class="page">
    <div id="page" class="hfeed page-wrapper">
      <Header2></Header2>
      <div id="site-main" class="site-main">
        <div id="main-content" class="main-content">
          <div id="primary" class="content-area">
            <section class="section section-padding m-b-70">
              <div class="section-container">
                <!-- <div>
                  <img
                    src="media/about/banner5.avif"
                    :style="{ height: '400px', width: '100%' }"
                    width=""
                    height="200"
                  />
                </div> -->
                <!-- Block Intro (Layout 3) -->
                <div class="block block-intro layout-3">
                  <div class="block-widget-wrap">
                    <div class="row">
                      <div class="section-column left">
                        <div class="intro-wrap">
                          <h2 class="intro-title">
                            Pramod Jewellers &<br />
                            Gift Center
                          </h2>
                          <div class="intro-item">
                            "Pramod Jewellers & Gift Center Nagpur is known to
                            satisfactorily cater the demands of its customer
                            base. Customer service is at the core of Pramod
                            Jewellers & Gift Center in Pardhi Nagar, Hingna
                            Road, Nagpur and it is this belief that has led the
                            business to build long-term relationships. Ensuring
                            a positive customer experience, making available
                            goods and/or services that are of top-notch quality
                            is given importance. It is one of the players in
                            Pendants, Necklace, Gold Ring, Mangalsutra, and
                            Swivel Ring to name a few. Also known for Jewellery
                            Showrooms, Gold Jewellery Showrooms, Gemstone
                            Dealers, Diamond Jewellery Showrooms, Bangle
                            Dealers, Imitation Jewellery Showrooms, Silver
                            Jewellery Showrooms, Antique Jewellery Showrooms and
                            much more."
                          </div>
                          <div class="intro-item">
                            Visit our online catalogue and shop for the finest
                            earrings, rings, bracelets, watches, silver, and the
                            most luxurious gemstones.
                          </div>
                          <!-- <div class="intro-btn">
                            <router-link
                              to="/about"
                              class="button button-outline animation-horizontal"
                              >Read more</router-link
                            >
                          </div> -->
                        </div>
                      </div>
                      <div
                        class="section-column right animation-horizontal hover-opacity"
                      >
                        <!-- <img
                        width="690"
                        height="498"
                        src="media/banner/intro-2.jpg"
                        alt="intro"
                      /> -->
                        <video width="640" height="498" controls loop>
                          <source src="media/video.mp4" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          
            <section class="section section-padding m-b-70">
              <div class="section-container">
                <!-- Block Intro (Layout 2) -->
                <div class="block block-intro layout-2 text-center">
                  <div class="block-widget-wrap">
                    <div class="intro-image animation-horizontal">
                      
                    </div>
                 
                    <h2 class="intro-title" style="text-align: center; color: #cb8161;
                    line-height: 36px;
                    font-size: 50px;
                    padding-bottom:18px;
                    font-family: Cormorant Garamond, serif;
                    " >
          <br />
          Our Mission
      </h2>
                    <div class="intro-text" style="font-size:24px">
                      "At Pramod Jewelers, our mission is to be the vanguard of traditional jewelry craftsmanship, blending heritage with modern aesthetics. We strive to create timeless pieces that reflect our commitment to excellence, authenticity, and the cultural richness of jewelry making. With a focus on preserving and celebrating the artistry passed down through generations, we aim to be a beacon of traditional values in the ever-evolving world of jewelry design."

                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="section section-padding m-b-70">
              <div class="section-container">
                <!-- Block Intro (Layout 2) -->
                <div class="block block-intro layout-2 text-center">
                  <div class="block-widget-wrap">
                    <div class="intro-image animation-horizontal">
                      
                    </div>
                    <h2 class="intro-title" style="text-align: center; color: #cb8161;
                    line-height: 36px;
                    font-size: 50px;
                    padding-bottom:18px;
                    font-family: Cormorant Garamond, serif;
                    " >
          <br />
          Our Vision
      </h2>
                    <div class="intro-text" style="font-size:24px">
                      "Our vision at Pramod Jewelers is to establish a global presence as a symbol of unrivaled craftsmanship and innovation. We aspire to be the preferred choice for individuals seeking exquisitely crafted jewelry that transcends trends and stands the test of time. By continuously evolving and adapting to contemporary tastes while upholding traditional values, we envision a future where Dhomne Jewelers is synonymous with elegance, trust, and a timeless legacy."

                    </div>
                  </div>
                </div>
              </div>
            </section>
          
            <section
              style=""
              class="section section-padding background-img  p-b-70 m-b-70"
            >
              <div class="section-container">
                <div class="block block-products layout-2 slider">
                  <div class="block-widget-wrap">
                    <div class="block-title txt_cent">
                      <!-- <h2 class="marbot">Awards Winning</h2> -->
                      <h2 class="intro-title" style="text-align: center; color: #cb8161;
                      line-height: 36px;
                      font-size: 50px;
                      padding-bottom:40px;
                      font-family: Cormorant Garamond, serif;
                      " >
            <br />
            Awards Winning
        </h2>
                    </div>
                    <div class="block-content">
                      <div class="content-product-list slick-wrap">
                        <div
                          class="slick-sliders products-list grid"
                          data-slidestoscroll="true"
                          data-dots="false"
                          data-nav="1"
                          data-columns4="1"
                          data-columns3="2"
                          data-columns2="2"
                          data-columns1="3"
                          data-columns1440="4"
                          data-columns="4"
                          v-slick
                        >
                          <div class="item-product slick-slide">
                            <div class="items">
                              <div
                                class="products-entry clearfix product-wapper"
                                style="padding-bottom: 0"
                              >
                                <div class="products-thumb">
                                  <div class="product-thumb-hover">
                                    <img
                                      width="600"
                                      height="800"
                                      src="media/about/award2.jpg"
                                      class="post-image"
                                      alt=""
                                    />
                                    <img
                                      width="600"
                                      height="600"
                                      src="media/about/award2.jpg"
                                      class="hover-image back"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-product slick-slide">
                            <div class="items">
                              <div
                                class="products-entry clearfix product-wapper"
                                style="padding-bottom: 0"
                              >
                                <div class="products-thumb">
                                  <div class="product-thumb-hover">
                                    <img
                                      width="400"
                                      height="400"
                                      src="media/about/award.jpg"
                                      class="post-image"
                                      alt=""
                                    />
                                    <img
                                      width="400"
                                      height="400"
                                      src="media/about/award.jpg"
                                      class="hover-image back"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-product slick-slide">
                            <div class="items">
                              <div
                                class="products-entry clearfix product-wapper"
                                style="padding-bottom: 0"
                              >
                                <div class="products-thumb">
                                  <div class="product-thumb-hover">
                                    <img
                                      width="600"
                                      height="600"
                                      src="media/about/award2.jpg"
                                      class="post-image"
                                      alt=""
                                    />
                                    <img
                                      width="600"
                                      height="600"
                                      src="media/about/award2.jpg"
                                      class="hover-image back"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="item-product slick-slide">
                            <div class="items">
                              <div
                                class="products-entry clearfix product-wapper"
                                style="padding-bottom: 0"
                              >
                                <div class="products-thumb">
                                  <div class="product-thumb-hover">
                                    <img
                                      width="600"
                                      height="600"
                                      src="media/about/award1.jpg"
                                      class="post-image"
                                      alt=""
                                    />
                                    <img
                                      width="600"
                                      height="600"
                                      src="media/about/award1.jpg"
                                      class="hover-image back"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="item-product slick-slide">
                            <div class="items">
                              <div
                                class="products-entry clearfix product-wapper"
                                style="padding-bottom: 0"
                              >
                                <div class="products-thumb">
                                  <div class="product-thumb-hover">
                                    <img
                                      width="600"
                                      height="600"
                                      src="media/about/award2.jpg"
                                      class="post-image"
                                      alt=""
                                    />
                                    <img
                                      width="600"
                                      height="600"
                                      src="media/about/award2.jpg"
                                      class="hover-image back"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- <section class="section section-padding m-b-70">
              <div class="section-container">
               
                <div class="block block-intro layout-3">
                  <div class="block-widget-wrap">
                    <div class="row">
                      <div class="section-column left">
                        <video width="640" height="498" controls loop>
                          <source src="media/pjvideo1.mp4" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                      <div
                        class="section-column right animation-horizontal hover-opacity"
                      >
                        <video width="640" height="498" controls loop>
                          <source src="media/video.mp4" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> -->
            <div>
              <h2 class="intro-title" style="text-align: center; color: #cb8161;
              line-height: 36px;
              font-size: 50px;
              padding-bottom:75px;
              " >
    <br />
    Our Brand
</h2>

              <section class="section section-padding m-b-70">
                <div class="section-container">
                  <!-- Block Intro (Layout 3) -->
                  <div class="block block-intro layout-3">
                    <div class="block-widget-wrap">
                      <div class="row" >
                        <div
                          class="section-column right animation-horizontal hover-opacity aboutbox"
                        >
                          <img
                            style="width: 100% ; object-fit:cover;"
                            src="media/shop10.jpeg"
                            alt="intro"
                          />
                        </div>
                        <div class="section-column ">
                          <div class="intro-wrap">
                            <h2 class="intro-title" >
                             <br />
                              Initial Foray
                            </h2>
  
                            <div class="intro-item">
                              "Swargiye Prashant Dhomne embarked on his journey as a
                               jewelry artisan, starting his first workshop in Gopal Nagar,
                                Nagpur. This humble beginning led to the establishment of
                                 his first small shop, named 'Durga Jewelers.' Prashant Dhomne,
                                 through his dedication and craftsmanship, turned this modest
                               establishment into a renowned name in the jewelry industry. After 
                               his demise, his son, Nishant Dhomne, took charge and successfully
                                managed to uphold the family legacy.
                              
                            </div>
                            <!-- <div class="intro-btn">
                              <router-link
                                to="/about"
                                class="button button-outline animation-horizontal"
                                >Read more</router-link
                              >
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="section section-padding m-b-70">
                <div class="section-container">
                  <!-- Block Intro (Layout 3) -->
                  <div class="block block-intro layout-3">
                    <div class="block-widget-wrap">
                      <div class="row">
                        <div class="section-column ">
                          <div class="intro-wrap">
                            <h2 class="intro-title" >
                             <br />
                             Epitomizing Excellence
                            </h2>
  
                            <div class="intro-item">
                              Nishant, a shining example for the younger generation, stepped into his
                               father's shoes and transformed the small shop into a prominent business.
                                Taking responsibility after Prashant's untimely demise, Nishant not only
                               maintained but also expanded the business. He exhibited exceptional entrepren-
                               eurial skills and successfully opened two grand showrooms in Nagpur – 
                               one in Pratap Nagar and the other in Hingna Pardhi Nagar.
  
                                Nishant Dhomne stands as a testament to the values and quality 
                                set by his father. In a time where many youngsters deviate from
                                traditional values, Nishant has not only adhered to his father's
                               principles but has also excelled. With innovative ideas and a 
                               fresh perspective, he has brought Dhomne Jewelers to new heights.
  
                                In these showrooms, Nishant imparts training to many, providing valuable insights into
                                 the art of jewelry making. His vision extends beyond Nagpur, and he aspires
                                  to take Dhomne Jewelers to every corner of Maharashtra, starting with Vidarbha.
                                   Nishant Dhomne is not just carrying on his father's legacy; he is also
                                    shaping the future of Dhomne Jewelers with dedication, commitment, and 
                                    a dream to make it a household name throughout Maharashtra."
  
  
                              
                            </div>
                            <!-- <div class="intro-btn">
                              <router-link
                                to="/about"
                                class="button button-outline animation-horizontal"
                                >Read more</router-link
                              >
                            </div> -->
                          </div>
                        </div>
                        <div
                          class="section-column right animation-horizontal hover-opacity aboutbox"
                        >
                          <img
                          style="width: 100% ; object-fit:cover;"

                            src="media/user1.jpeg"
                            alt="intro"
                          />
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            
<!--            
            <section>
              <h2 class="intro-title" style="text-align:center">Our Vision:</h2>
              <div class="intro-item">
                "Our vision at Dhomne Jewelers is to establish a global presence as a symbol of unrivaled craftsmanship and innovation. We aspire to be the preferred choice for individuals seeking exquisitely crafted jewelry that transcends trends and stands the test of time. By continuously evolving and adapting to contemporary tastes while upholding traditional values, we envision a future where Dhomne Jewelers is synonymous with elegance, trust, and a timeless legacy."
                
              </div>
            </section> -->
           
            <!-- #content -->
          </div>
          <!-- #primary -->
        </div>
        <!-- #main-content -->
      </div>

      <Footer></Footer>
    </div>

    <!-- Back Top button -->
    <div class="back-top button-show" @click="scrollToTop">
      <i class="arrow_carrot-up"></i>
    </div>

    <!-- Search -->
    <div class="search-overlay">
      <div class="close-search"></div>
      <div class="wrapper-search">
        <form
          role="search"
          method="get"
          class="search-from ajax-search"
          action="#"
        >
          <a href="#" class="search-close"></a>
          <div class="search-box">
            <button id="searchsubmit" class="btn" type="submit">
              <i class="icon-search"></i>
            </button>
            <input
              type="text"
              autocomplete="off"
              value=""
              name="s"
              class="input-search s"
              placeholder="Search..."
            />
            <div class="content-menu_search">
              <label>Suggested</label>
              <ul id="menu_search" class="menu">
                <li><a href="#">Earrings</a></li>
                <li><a href="#">Necklaces</a></li>
                <li><a href="#">Bracelets</a></li>
                <li><a href="#">Jewelry Box</a></li>
              </ul>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- Wishlist -->
    <div class="wishlist-popup">
      <div class="wishlist-popup-inner">
        <div class="wishlist-popup-content">
          <div class="wishlist-popup-content-top">
            <span class="wishlist-name">Wishlist</span>
            <span class="wishlist-count-wrapper"
              ><span class="wishlist-count">2</span></span
            >
            <span class="wishlist-popup-close"></span>
          </div>
          <div class="wishlist-popup-content-mid">
            <table class="wishlist-items">
              <tbody>
                <tr class="wishlist-item">
                  <td class="wishlist-item-remove"><span></span></td>
                  <td class="wishlist-item-image">
                    <a href="shop-details.html">
                      <img
                        width="600"
                        height="600"
                        src="media/product/3.jpg"
                        alt=""
                      />
                    </a>
                  </td>
                  <td class="wishlist-item-info">
                    <div class="wishlist-item-name">
                      <a href="shop-details.html">Twin Hoops</a>
                    </div>
                    <div class="wishlist-item-price">
                      <span>$150.00</span>
                    </div>
                    <div class="wishlist-item-time">June 4, 2022</div>
                  </td>
                  <td class="wishlist-item-actions">
                    <div class="wishlist-item-stock">In stock</div>
                    <div class="wishlist-item-add">
                      <div data-title="Add to cart">
                        <a rel="nofollow" href="#">Add to cart</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="wishlist-item">
                  <td class="wishlist-item-remove"><span></span></td>
                  <td class="wishlist-item-image">
                    <a href="shop-details.html">
                      <img
                        width="600"
                        height="600"
                        src="media/product/4.jpg"
                        alt=""
                      />
                    </a>
                  </td>
                  <td class="wishlist-item-info">
                    <div class="wishlist-item-name">
                      <a href="shop-details.html"
                        >Yilver And Turquoise Earrings</a
                      >
                    </div>
                    <div class="wishlist-item-price">
                      <del aria-hidden="true"><span>$150.00</span></del>
                      <ins><span>$100.00</span></ins>
                    </div>
                    <div class="wishlist-item-time">June 4, 2022</div>
                  </td>
                  <td class="wishlist-item-actions">
                    <div class="wishlist-item-stock">In stock</div>
                    <div class="wishlist-item-add">
                      <div data-title="Add to cart">
                        <a rel="nofollow" href="#">Add to cart</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="wishlist-popup-content-bot">
            <div class="wishlist-popup-content-bot-inner">
              <a class="wishlist-page" href="shop-wishlist.html">
                Open wishlist page
              </a>
              <span class="wishlist-continue" data-url="">
                Continue shopping
              </span>
            </div>
            <div class="wishlist-notice wishlist-notice-show">
              Added to the wishlist!
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Compare -->
    <div class="compare-popup">
      <div class="compare-popup-inner">
        <div class="compare-table">
          <div class="compare-table-inner">
            <a href="#" id="compare-table-close" class="compare-table-close">
              <span class="compare-table-close-icon"></span>
            </a>
            <div class="compare-table-items">
              <table id="product-table" class="product-table">
                <thead>
                  <tr>
                    <th>
                      <a href="#" class="compare-table-settings">Settings</a>
                    </th>
                    <th>
                      <a href="shop-details.html">Twin Hoops</a>
                      <span class="remove">remove</span>
                    </th>
                    <th>
                      <a href="shop-details.html">Medium Flat Hoops</a>
                      <span class="remove">remove</span>
                    </th>
                    <th>
                      <a href="shop-details.html">Bold Pearl Hoop Earrings</a>
                      <span class="remove">remove</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="tr-image">
                    <td class="td-label">Image</td>
                    <td>
                      <a href="shop-details.html">
                        <img
                          width="600"
                          height="600"
                          src="media/product/3.jpg"
                          alt=""
                        />
                      </a>
                    </td>
                    <td>
                      <a href="shop-details.html">
                        <img
                          width="600"
                          height="600"
                          src="media/product/1.jpg"
                          alt=""
                        />
                      </a>
                    </td>
                    <td>
                      <a href="shop-details.html">
                        <img
                          width="600"
                          height="600"
                          src="media/product/2.jpg"
                          alt=""
                        />
                      </a>
                    </td>
                  </tr>
                  <tr class="tr-sku">
                    <td class="td-label">SKU</td>
                    <td>VN00189</td>
                    <td></td>
                    <td>D1116</td>
                  </tr>
                  <tr class="tr-rating">
                    <td class="td-label">Rating</td>
                    <td>
                      <div class="star-rating">
                        <span style="width: 80%"></span>
                      </div>
                    </td>
                    <td>
                      <div class="star-rating">
                        <span style="width: 100%"></span>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr class="tr-price">
                    <td class="td-label">Price</td>
                    <td><span class="amount">$150.00</span></td>
                    <td>
                      <del><span class="amount">$150.00</span></del>
                      <ins><span class="amount">$100.00</span></ins>
                    </td>
                    <td><span class="amount">$200.00</span></td>
                  </tr>
                  <tr class="tr-add-to-cart">
                    <td class="td-label">Add to cart</td>
                    <td>
                      <div data-title="Add to cart">
                        <a href="#" class="button">Add to cart</a>
                      </div>
                    </td>
                    <td>
                      <div data-title="Add to cart">
                        <a href="#" class="button">Add to cart</a>
                      </div>
                    </td>
                    <td>
                      <div data-title="Add to cart">
                        <a href="#" class="button">Add to cart</a>
                      </div>
                    </td>
                  </tr>
                  <tr class="tr-description">
                    <td class="td-label">Description</td>
                    <td>
                      Phasellus sed volutpat orci. Fusce eget lore mauris
                      vehicula elementum gravida nec dui. Aenean aliquam varius
                      ipsum, non ultricies tellus sodales eu. Donec dignissim
                      viverra nunc, ut aliquet magna posuere eget.
                    </td>
                    <td>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </td>
                    <td>
                      The EcoSmart Fleece Hoodie full-zip hooded jacket provides
                      medium weight fleece comfort all year around. Feel better
                      in this sweatshirt because Hanes keeps plastic bottles of
                      landfills by using recycled polyester.7.8 ounce fleece
                      sweatshirt made with up to 5 percent polyester created
                      from recycled plastic.
                    </td>
                  </tr>
                  <tr class="tr-content">
                    <td class="td-label">Content</td>
                    <td>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum. Sed ut
                      perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium doloremque laudantium, totam rem aperiam,
                      eaque ipsa quae ab illo inventore veritatis et quasi
                      architecto beatae vitae dicta sunt explicabo. Nemo enim
                      ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                      fugit, sed quia consequuntur magni dolores eos qui ratione
                      voluptatem sequi nesciunt. Neque porro quisquam est, qui
                      dolorem ipsum quia dolor sit amet, consectetur, adipisci
                      velit, sed quia non numquam eius modi tempora incidunt ut
                      labore et dolore magnam aliquam quaerat voluptatem.
                    </td>
                    <td>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum. Sed ut
                      perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium doloremque laudantium, totam rem aperiam,
                      eaque ipsa quae ab illo inventore veritatis et quasi
                      architecto beatae vitae dicta sunt explicabo. Nemo enim
                      ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                      fugit, sed quia consequuntur magni dolores eos qui ratione
                      voluptatem sequi nesciunt. Neque porro quisquam est, qui
                      dolorem ipsum quia dolor sit amet, consectetur, adipisci
                      velit, sed quia non numquam eius modi tempora incidunt ut
                      labore et dolore magnam aliquam quaerat voluptatem.
                    </td>
                    <td>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum. Sed ut
                      perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium doloremque laudantium, totam rem aperiam,
                      eaque ipsa quae ab illo inventore veritatis et quasi
                      architecto beatae vitae dicta sunt explicabo. Nemo enim
                      ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                      fugit, sed quia consequuntur magni dolores eos qui ratione
                      voluptatem sequi nesciunt. Neque porro quisquam est, qui
                      dolorem ipsum quia dolor sit amet, consectetur, adipisci
                      velit, sed quia non numquam eius modi tempora incidunt ut
                      labore et dolore magnam aliquam quaerat voluptatem.
                    </td>
                  </tr>
                  <tr class="tr-dimensions">
                    <td class="td-label">Dimensions</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Quickview -->
    <div class="quickview-popup">
      <div id="quickview-container">
        <div class="quickview-container">
          <a href="#" class="quickview-close"></a>
          <div class="quickview-notices-wrapper"></div>
          <div class="product single-product product-type-simple">
            <div class="product-detail">
              <div class="row">
                <div class="img-quickview">
                  <div class="product-images-slider">
                    <div id="quickview-slick-carousel">
                      <div class="images">
                        <div class="scroll-image">
                          <div class="slick-wrap">
                            <div
                              class="slick-sliders image-additional"
                              data-dots="true"
                              data-columns4="1"
                              data-columns3="1"
                              data-columns2="1"
                              data-columns1="1"
                              data-columns="1"
                              data-nav="true"
                            >
                              <div class="img-thumbnail slick-slide">
                                <a
                                  href="media/product/3.jpg"
                                  class="image-scroll"
                                  title=""
                                >
                                  <img
                                    width="900"
                                    height="900"
                                    src="media/product/3.jpg"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div class="img-thumbnail slick-slide">
                                <a
                                  href="media/product/3-2.jpg"
                                  class="image-scroll"
                                  title=""
                                >
                                  <img
                                    width="900"
                                    height="900"
                                    src="media/product/3-2.jpg"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="quickview-single-info">
                  <div class="product-content-detail entry-summary">
                    <h1 class="product-title entry-title">Twin Hoops</h1>
                    <div class="price-single">
                      <div class="price">
                        <del><span>$150.00</span></del>
                        <span>$100.00</span>
                      </div>
                    </div>
                    <div class="product-rating">
                      <div
                        class="star-rating"
                        role="img"
                        aria-label="Rated 4.00 out of 5"
                      >
                        <span style="width: 80%"
                          >Rated <strong class="rating">4.00</strong> out of 5
                          based on <span class="rating">1</span> customer
                          rating</span
                        >
                      </div>
                      <a href="#" class="review-link"
                        >(<span class="count">1</span> customer review)</a
                      >
                    </div>
                    <div class="description">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis…
                      </p>
                    </div>
                    <form
                      class="cart"
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <div class="quantity-button">
                        <div class="quantity">
                          <button type="button" class="plus">+</button>
                          <input
                            type="number"
                            class="input-text qty text"
                            step="1"
                            min="1"
                            max=""
                            name="quantity"
                            value="1"
                            title="Qty"
                            size="4"
                            placeholder=""
                            inputmode="numeric"
                            autocomplete="off"
                          />
                          <button type="button" class="minus">-</button>
                        </div>
                        <button
                          type="submit"
                          class="single-add-to-cart-button button alt"
                        >
                          Add to cart
                        </button>
                      </div>
                      <button class="button quick-buy">Buy It Now</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>

    <!-- Page Loader -->
  </body>
</template>

<script>
import Header2 from "./Header2.vue";
import Footer from "./Footer.vue";

// About.vue
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel";
import $ from "jquery";

export default {
  name: "AboutPage",
  components: {
    Header2,
    Footer,
  },
  methods: {
    scrollToTop() {
      // Smooth scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },

  directives: {
    slick: {
      mounted(el) {
        $(el).slick({
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
          // Add other options as needed
        });
      },
    },
  },
};
</script>
