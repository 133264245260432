<template>
    <body class="page">
      <div id="page" class="hfeed page-wrapper">
        <Header2></Header2>
        <div style="display: flex; justify-content: center; align-items: center; text-align: center;">
          <div>
            <h2 class="page-title">Terms and Conditions</h2>
            <p>
              Welcome to Pramod Jewelers Nagapur. These terms and conditions outline the rules and regulations for the use of our website.
            </p>
  
            <h3>1. Intellectual Property Rights</h3>
            <p>
              Unless otherwise stated, Pramod Jewelers Nagapur and/or its licensors own the intellectual property rights for all material on this website.
            </p>
  
            <h3>2. Restrictions</h3>
            <p>
              You are specifically restricted from all of the following:
            </p>
            <ul style="list-style:none">
              <li>publishing any website material in any other media;</li>
              <li>selling, sublicensing, and/or otherwise commercializing any website material;</li>
              <li>publicly performing and/or showing any website material;</li>
              <li>using this website in any way that is or may be damaging to this website;</li>
              <li>engaging in any data mining, data harvesting, data extracting, or any other similar activity;</li>
            </ul>
  
          
  
            <h3>3. Variation of Terms</h3>
            <p>
              Pramod Jewelers Nagapur is permitted to revise these terms at any time as it sees fit, and by using this website, you are expected to review these terms regularly.
            </p>
  
            <h3>4. Governing Law & Jurisdiction</h3>
            <p>
              These terms will be governed by and construed in accordance with the laws of the state of  Maharashtra, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Nagpur for the resolution of any disputes.
            </p>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </body>
  </template>
  
  
  
  <script>
  import Header2 from "./Header2.vue";
import Footer from "./Footer.vue";
  export default {
    name: "TcPage",
    components: {
    Header2,
    Footer,
  },
  
  };
  </script>
 