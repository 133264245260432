import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
//import Toasted from 'vue-toasted'; // Import vue-toasted
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Productus from "./components/Productus";
import ShopGrid from "./components/ShopGrid";
import Earring from "./components/Productss/Earring";
import Brecelate from "./components/Productss/Brecelate";
import Nackless from "./components/Productss/Nackless";
import Ring from "./components/Productss/Ring";
import Jwelrybox from "./components/Productss/Jwelrybox";
import Gold from "./components/MainProducts/Gold";
import Silver from "./components/MainProducts/Silver";
import Platinum from "./components/MainProducts/Platinum";
import PrivecyPolicy from './components/PrivecyPolicy'
import Tc from './components/Tc'
import Gellrey from './components/Productss/Gellrey'
const routes = [
  { path: "/", component: Home },
  { path: "/about", component: About, meta: { keepAlive: true } },
  { path: "/contact", component: Contact, name: Contact },
  { path: "/shopdeteils", component: Productus },
  { path: "/shopgrid", component: ShopGrid },
  { path: "/earring", component: Earring },
  { path: "/breclate", component: Brecelate },
  { path: "/neckless", component: Nackless },
  { path: "/ring", component: Ring },
  { path: "/Jwelrybox", component: Jwelrybox },
  { path: "/Gold", component: Gold },
  { path: "/Silver", component: Silver },
  { path: "/Platinum", component: Platinum },
  { path: "/privicy-policy", component: PrivecyPolicy },
  { path: "/tc", component: Tc },
  { path: "/gallery", component: Gellrey },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);

// Use vue-toasted globally
 //app.use(Toasted).mount("#app");

app.use(router).mount("#app");
