<template>
  <Header2></Header2>
  <body class="shop">
    <div id="page" class="hfeed page-wrapper">
      <div id="site-main" class="site-main">
        <div id="main-content" class="main-content">
          <div id="primary" class="content-area">
            <div
              id="title"
              class="page-title"
              style="
                background-image: url('media/product/ringg.jpg');
                background-size: cover;
                background-position: center center;
                width: 100%;
                height: 300px;
                text-align: left;
              "
            >
              <div class="section-container">
                <div class="content-title-heading">
                  <h1 class="text-title-heading" style="margin-left: 100px">
                    Ring
                  </h1>
                </div>
              </div>
            </div>

            <div id="content" class="site-content" role="main">
              <div class="section-padding">
                <div class="section-container p-l-r">
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                      <div class="tab-content">
                        <div
                          class="tab-pane fade show active"
                          id="layout-grid"
                          role="tabpanel"
                        >
                          <div class="products-list grid">
                            <div class="row">
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3140.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3143.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3169.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3171.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3178.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3179.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3175.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3175.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3160.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3158.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3181.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3183.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3246.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3247.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3249.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3249.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3251.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3251.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3260.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3260.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover border"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3258.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A3258.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <div
                                  class="products-entry clearfix product-wapper"
                                >
                                  <div class="products-thumb">
                                    <div
                                      class="product-thumb-hover"
                                      style="width: 300px"
                                    >
                                      <a href="#">
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A5485.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          style="width: 100%; height: 300px"
                                          src="media/rings/0G1A5488.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="layout-list"
                          role="tabpanel"
                        >
                          <div class="products-list list">
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/1.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/1-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#">Medium Flat Hoops</a>
                                    </h3>
                                    <span class="price">$150.00</span>
                                    <div class="rating">
                                      <div class="star star-5"></div>
                                      <div class="review-count">
                                        (1<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover border">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/5.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/5-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#"
                                        >Yilver And Turquoise Earrings</a
                                      >
                                    </h3>
                                    <span class="price">
                                      <del aria-hidden="true"
                                        ><span>$150.00</span></del
                                      >
                                      <ins><span>$100.00</span></ins>
                                    </span>
                                    <div class="rating">
                                      <div class="star star-0"></div>
                                      <div class="review-count">
                                        (0<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/2.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/2-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#">Bold Pearl Hoop Earrings</a>
                                    </h3>
                                    <span class="price">$150.00</span>
                                    <div class="rating">
                                      <div class="star star-4"></div>
                                      <div class="review-count">
                                        (1<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover border">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/6.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/6-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#">Bora Armchair</a>
                                    </h3>
                                    <span class="price">
                                      <del aria-hidden="true"
                                        ><span>$120.00</span></del
                                      >
                                      <ins><span>$100.00</span></ins>
                                    </span>
                                    <div class="rating">
                                      <div class="star star-0"></div>
                                      <div class="review-count">
                                        (0<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/3.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/3-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#">Twin Hoops</a>
                                    </h3>
                                    <span class="price">
                                      <del aria-hidden="true"
                                        ><span>$100.00</span></del
                                      >
                                      <ins><span>$90.00</span></ins>
                                    </span>
                                    <div class="rating">
                                      <div class="star star-5"></div>
                                      <div class="review-count">
                                        (3<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover border">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/7.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/7-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#">Diamond Bracelet</a>
                                    </h3>
                                    <span class="price">
                                      <del aria-hidden="true"
                                        ><span>$79.00</span></del
                                      >
                                      <ins><span>$50.00</span></ins>
                                    </span>
                                    <div class="rating">
                                      <div class="star star-5"></div>
                                      <div class="review-count">
                                        (2<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/4.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/4-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#"
                                        >Yilver And Turquoise Earrings</a
                                      >
                                    </h3>
                                    <span class="price">$120.00</span>
                                    <div class="rating">
                                      <div class="star star-4"></div>
                                      <div class="review-count">
                                        (1<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover border">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/8.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/8-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#">X Hoop Earrings</a>
                                    </h3>
                                    <span class="price">
                                      <del aria-hidden="true"
                                        ><span>$200.00</span></del
                                      >
                                      <ins><span>$180.00</span></ins>
                                    </span>
                                    <div class="rating">
                                      <div class="star star-5"></div>
                                      <div class="review-count">
                                        (4<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover border">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/9.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/9-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#">Yintage Medallion Necklace</a>
                                    </h3>
                                    <span class="price">$140.00</span>
                                    <div class="rating">
                                      <div class="star star-5"></div>
                                      <div class="review-count">
                                        (1<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/13.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/13-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#">Medium Flat Hoops</a>
                                    </h3>
                                    <span class="price">$150.00</span>
                                    <div class="rating">
                                      <div class="star star-5"></div>
                                      <div class="review-count">
                                        (1<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover border">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/11.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/11-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#"
                                        >Yilver And Turquoise Earrings</a
                                      >
                                    </h3>
                                    <span class="price">
                                      <del aria-hidden="true"
                                        ><span>$150.00</span></del
                                      >
                                      <ins><span>$100.00</span></ins>
                                    </span>
                                    <div class="rating">
                                      <div class="star star-0"></div>
                                      <div class="review-count">
                                        (0<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="products-entry clearfix product-wapper">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="products-thumb">
                                    <div class="product-lable">
                                      <div class="hot">Hot</div>
                                    </div>
                                    <div class="product-thumb-hover">
                                      <a href="#">
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/14.jpg"
                                          class="post-image"
                                          alt=""
                                        />
                                        <img
                                          width="600"
                                          height="600"
                                          src="media/product/14-2.jpg"
                                          class="hover-image back"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <span
                                      class="product-quickview"
                                      data-title="Quick View"
                                    >
                                      <a
                                        href="#"
                                        class="quickview quickview-button"
                                        >Quick View <i class="icon-search"></i
                                      ></a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="products-content">
                                    <h3 class="product-title">
                                      <a href="#">Bold Pearl Hoop Earrings</a>
                                    </h3>
                                    <span class="price">$150.00</span>
                                    <div class="rating">
                                      <div class="star star-4"></div>
                                      <div class="review-count">
                                        (1<span> review</span>)
                                      </div>
                                    </div>
                                    <div class="product-button">
                                      <div
                                        class="btn-add-to-cart"
                                        data-title="Add to cart"
                                      >
                                        <a
                                          rel="nofollow"
                                          href="#"
                                          class="product-btn button"
                                          >Add to cart</a
                                        >
                                      </div>
                                      <div
                                        class="btn-wishlist"
                                        data-title="Wishlist"
                                      >
                                        <button class="product-btn">
                                          Add to wishlist
                                        </button>
                                      </div>
                                      <div
                                        class="btn-compare"
                                        data-title="Compare"
                                      >
                                        <button class="product-btn">
                                          Compare
                                        </button>
                                      </div>
                                    </div>
                                    <div class="product-description">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis…
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- #content -->
          </div>
          <!-- #primary -->
        </div>
        <!-- #main-content -->
      </div>

      <Footer></Footer>
    </div>

    <!-- Back Top button -->
    <div class="back-top button-show" @click="scrollToTop">
      <i class="arrow_carrot-up"></i>
    </div>

    <!-- Search -->
    <div class="search-overlay">
      <div class="close-search"></div>
      <div class="wrapper-search">
        <form
          role="search"
          method="get"
          class="search-from ajax-search"
          action="#"
        >
          <a href="#" class="search-close"></a>
          <div class="search-box">
            <button id="searchsubmit" class="btn" type="submit">
              <i class="icon-search"></i>
            </button>
            <input
              type="text"
              autocomplete="off"
              value=""
              name="s"
              class="input-search s"
              placeholder="Search..."
            />
            <div class="content-menu_search">
              <label>Suggested</label>
              <ul id="menu_search" class="menu">
                <li><a href="#">Earrings</a></li>
                <li><a href="#">Necklaces</a></li>
                <li><a href="#">Bracelets</a></li>
                <li><a href="#">Jewelry Box</a></li>
              </ul>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- Wishlist -->
    <div class="wishlist-popup">
      <div class="wishlist-popup-inner">
        <div class="wishlist-popup-content">
          <div class="wishlist-popup-content-top">
            <span class="wishlist-name">Wishlist</span>
            <span class="wishlist-count-wrapper"
              ><span class="wishlist-count">2</span></span
            >
            <span class="wishlist-popup-close"></span>
          </div>
          <div class="wishlist-popup-content-mid">
            <table class="wishlist-items">
              <tbody>
                <tr class="wishlist-item">
                  <td class="wishlist-item-remove"><span></span></td>
                  <td class="wishlist-item-image">
                    <a href="#">
                      <img
                        width="600"
                        height="600"
                        src="media/product/3.jpg"
                        alt=""
                      />
                    </a>
                  </td>
                  <td class="wishlist-item-info">
                    <div class="wishlist-item-name">
                      <a href="#">Twin Hoops</a>
                    </div>
                    <div class="wishlist-item-price">
                      <span>$150.00</span>
                    </div>
                    <div class="wishlist-item-time">June 4, 2022</div>
                  </td>
                  <td class="wishlist-item-actions">
                    <div class="wishlist-item-stock">In stock</div>
                    <div class="wishlist-item-add">
                      <div data-title="Add to cart">
                        <a rel="nofollow" href="#">Add to cart</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="wishlist-item">
                  <td class="wishlist-item-remove"><span></span></td>
                  <td class="wishlist-item-image">
                    <a href="#">
                      <img
                        width="600"
                        height="600"
                        src="media/product/4.jpg"
                        alt=""
                      />
                    </a>
                  </td>
                  <td class="wishlist-item-info">
                    <div class="wishlist-item-name">
                      <a href="#">Yilver And Turquoise Earrings</a>
                    </div>
                    <div class="wishlist-item-price">
                      <del aria-hidden="true"><span>$150.00</span></del>
                      <ins><span>$100.00</span></ins>
                    </div>
                    <div class="wishlist-item-time">June 4, 2022</div>
                  </td>
                  <td class="wishlist-item-actions">
                    <div class="wishlist-item-stock">In stock</div>
                    <div class="wishlist-item-add">
                      <div data-title="Add to cart">
                        <a rel="nofollow" href="#">Add to cart</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="wishlist-popup-content-bot">
            <div class="wishlist-popup-content-bot-inner">
              <a class="wishlist-page" href="shop-wishlist.html">
                Open wishlist page
              </a>
              <span class="wishlist-continue" data-url="">
                Continue shopping
              </span>
            </div>
            <div class="wishlist-notice wishlist-notice-show">
              Added to the wishlist!
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Compare -->
    <div class="compare-popup">
      <div class="compare-popup-inner">
        <div class="compare-table">
          <div class="compare-table-inner">
            <a href="#" id="compare-table-close" class="compare-table-close">
              <span class="compare-table-close-icon"></span>
            </a>
            <div class="compare-table-items">
              <table id="product-table" class="product-table">
                <thead>
                  <tr>
                    <th>
                      <a href="#" class="compare-table-settings">Settings</a>
                    </th>
                    <th>
                      <a href="#">Twin Hoops</a>
                      <span class="remove">remove</span>
                    </th>
                    <th>
                      <a href="#">Medium Flat Hoops</a>
                      <span class="remove">remove</span>
                    </th>
                    <th>
                      <a href="#">Bold Pearl Hoop Earrings</a>
                      <span class="remove">remove</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="tr-image">
                    <td class="td-label">Image</td>
                    <td>
                      <a href="#">
                        <img
                          width="600"
                          height="600"
                          src="media/product/3.jpg"
                          alt=""
                        />
                      </a>
                    </td>
                    <td>
                      <a href="#">
                        <img
                          width="600"
                          height="600"
                          src="media/product/1.jpg"
                          alt=""
                        />
                      </a>
                    </td>
                    <td>
                      <a href="#">
                        <img
                          width="600"
                          height="600"
                          src="media/product/2.jpg"
                          alt=""
                        />
                      </a>
                    </td>
                  </tr>
                  <tr class="tr-sku">
                    <td class="td-label">SKU</td>
                    <td>VN00189</td>
                    <td></td>
                    <td>D1116</td>
                  </tr>
                  <tr class="tr-rating">
                    <td class="td-label">Rating</td>
                    <td>
                      <div class="star-rating">
                        <span style="width: 80%"></span>
                      </div>
                    </td>
                    <td>
                      <div class="star-rating">
                        <span style="width: 100%"></span>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr class="tr-price">
                    <td class="td-label">Price</td>
                    <td><span class="amount">$150.00</span></td>
                    <td>
                      <del><span class="amount">$150.00</span></del>
                      <ins><span class="amount">$100.00</span></ins>
                    </td>
                    <td><span class="amount">$200.00</span></td>
                  </tr>
                  <tr class="tr-add-to-cart">
                    <td class="td-label">Add to cart</td>
                    <td>
                      <div data-title="Add to cart">
                        <a href="#" class="button">Add to cart</a>
                      </div>
                    </td>
                    <td>
                      <div data-title="Add to cart">
                        <a href="#" class="button">Add to cart</a>
                      </div>
                    </td>
                    <td>
                      <div data-title="Add to cart">
                        <a href="#" class="button">Add to cart</a>
                      </div>
                    </td>
                  </tr>
                  <tr class="tr-description">
                    <td class="td-label">Description</td>
                    <td>
                      Phasellus sed volutpat orci. Fusce eget lore mauris
                      vehicula elementum gravida nec dui. Aenean aliquam varius
                      ipsum, non ultricies tellus sodales eu. Donec dignissim
                      viverra nunc, ut aliquet magna posuere eget.
                    </td>
                    <td>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </td>
                    <td>
                      The EcoSmart Fleece Hoodie full-zip hooded jacket provides
                      medium weight fleece comfort all year around. Feel better
                      in this sweatshirt because Hanes keeps plastic bottles of
                      landfills by using recycled polyester.7.8 ounce fleece
                      sweatshirt made with up to 5 percent polyester created
                      from recycled plastic.
                    </td>
                  </tr>
                  <tr class="tr-content">
                    <td class="td-label">Content</td>
                    <td>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum. Sed ut
                      perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium doloremque laudantium, totam rem aperiam,
                      eaque ipsa quae ab illo inventore veritatis et quasi
                      architecto beatae vitae dicta sunt explicabo. Nemo enim
                      ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                      fugit, sed quia consequuntur magni dolores eos qui ratione
                      voluptatem sequi nesciunt. Neque porro quisquam est, qui
                      dolorem ipsum quia dolor sit amet, consectetur, adipisci
                      velit, sed quia non numquam eius modi tempora incidunt ut
                      labore et dolore magnam aliquam quaerat voluptatem.
                    </td>
                    <td>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum. Sed ut
                      perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium doloremque laudantium, totam rem aperiam,
                      eaque ipsa quae ab illo inventore veritatis et quasi
                      architecto beatae vitae dicta sunt explicabo. Nemo enim
                      ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                      fugit, sed quia consequuntur magni dolores eos qui ratione
                      voluptatem sequi nesciunt. Neque porro quisquam est, qui
                      dolorem ipsum quia dolor sit amet, consectetur, adipisci
                      velit, sed quia non numquam eius modi tempora incidunt ut
                      labore et dolore magnam aliquam quaerat voluptatem.
                    </td>
                    <td>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum. Sed ut
                      perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium doloremque laudantium, totam rem aperiam,
                      eaque ipsa quae ab illo inventore veritatis et quasi
                      architecto beatae vitae dicta sunt explicabo. Nemo enim
                      ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                      fugit, sed quia consequuntur magni dolores eos qui ratione
                      voluptatem sequi nesciunt. Neque porro quisquam est, qui
                      dolorem ipsum quia dolor sit amet, consectetur, adipisci
                      velit, sed quia non numquam eius modi tempora incidunt ut
                      labore et dolore magnam aliquam quaerat voluptatem.
                    </td>
                  </tr>
                  <tr class="tr-dimensions">
                    <td class="td-label">Dimensions</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Quickview -->
    <div class="quickview-popup">
      <div id="quickview-container">
        <div class="quickview-container">
          <a href="#" class="quickview-close"></a>
          <div class="quickview-notices-wrapper"></div>
          <div class="product single-product product-type-simple">
            <div class="product-detail">
              <div class="row">
                <div class="img-quickview">
                  <div class="product-images-slider">
                    <div id="quickview-slick-carousel">
                      <div class="images">
                        <div class="scroll-image">
                          <div class="slick-wrap">
                            <div
                              class="slick-sliders image-additional"
                              data-dots="true"
                              data-columns4="1"
                              data-columns3="1"
                              data-columns2="1"
                              data-columns1="1"
                              data-columns="1"
                              data-nav="true"
                            >
                              <div class="img-thumbnail slick-slide">
                                <a
                                  href="media/product/3.jpg"
                                  class="image-scroll"
                                  title=""
                                >
                                  <img
                                    width="900"
                                    height="900"
                                    src="media/product/3.jpg"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div class="img-thumbnail slick-slide">
                                <a
                                  href="media/product/3-2.jpg"
                                  class="image-scroll"
                                  title=""
                                >
                                  <img
                                    width="900"
                                    height="900"
                                    src="media/product/3-2.jpg"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="quickview-single-info">
                  <div class="product-content-detail entry-summary">
                    <h1 class="product-title entry-title">Twin Hoops</h1>
                    <div class="price-single">
                      <div class="price">
                        <del><span>$150.00</span></del>
                        <span>$100.00</span>
                      </div>
                    </div>
                    <div class="product-rating">
                      <div
                        class="star-rating"
                        role="img"
                        aria-label="Rated 4.00 out of 5"
                      >
                        <span style="width: 80%"
                          >Rated <strong class="rating">4.00</strong> out of 5
                          based on <span class="rating">1</span> customer
                          rating</span
                        >
                      </div>
                      <a href="#" class="review-link"
                        >(<span class="count">1</span> customer review)</a
                      >
                    </div>
                    <div class="description">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis…
                      </p>
                    </div>
                    <form
                      class="cart"
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <div class="quantity-button">
                        <div class="quantity">
                          <button type="button" class="plus">+</button>
                          <input
                            type="number"
                            class="input-text qty text"
                            step="1"
                            min="1"
                            max=""
                            name="quantity"
                            value="1"
                            title="Qty"
                            size="4"
                            placeholder=""
                            inputmode="numeric"
                            autocomplete="off"
                          />
                          <button type="button" class="minus">-</button>
                        </div>
                        <button
                          type="submit"
                          class="single-add-to-cart-button button alt"
                        >
                          Add to cart
                        </button>
                      </div>
                      <button class="button quick-buy">Buy It Now</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>

    <!-- Page Loader -->
  </body>
</template>

<script>
import Header2 from "../Header2.vue";
import Footer from "../Footer.vue";
export default {
  name: "RingPage",
  components: {
    Header2,
    Footer,
  },
  methods: {
    scrollToTop() {
      // Smooth scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
